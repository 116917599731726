import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "authentication"
    }}>{`Authentication`}</h1>
    <p>{`We're using `}<a parentName="p" {...{
        "href": "https://useauth.dev"
      }}>{`useAuth`}</a>{` for authentication.`}</p>
    <ol>
      <li parentName="ol">{`Setup a new Auth0 app`}</li>
      <li parentName="ol">{`Follow instructions in `}<a parentName="li" {...{
          "href": "https://useauth.dev/docs/getting-started"
        }}>{`getting started docs`}</a></li>
      <li parentName="ol">{`Block twitshot access after 5 pics (use localstorage)`}</li>
    </ol>
    <p>{`Authentication is not `}<em parentName="p">{`super`}</em>{` necessary for this app, but it gives paying users the peace of mind that they're logged in. It also enables session sharing between devices.`}</p>
    <p>{`And it's a good excuse to try it out :)`}</p>
    <h2 {...{
      "id": "user-roles"
    }}>{`User roles`}</h2>
    <p>{`We'll need user roles later. Create a `}<inlineCode parentName="p">{`Rule`}</inlineCode>{` on Auth0 to ensure role info is included with user data.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function (user, context, callback) {
  const namespace = 'https://YOUR_DOMAIN';
  const assignedRoles = (context.authorization || {}).roles;
  user.user_metadata = user.user_metadata || {};
  user.user_metadata.roles = assignedRoles;
  context.idToken[namespace + '/user_metadata'] = user.user_metadata;
  callback(null, user, context);
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      